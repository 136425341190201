<template>
  <v-container class="container-max-width">
    <v-row v-if="isLoading" id="syncio-loading-logo">
      <v-col class="text-center">
        <loading-syncio-logo></loading-syncio-logo>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-row>
          <v-col cols="12" class="align-center">
            <div class="text-center relative">
              <v-icon size="40" @click="$router.go(-1)" class="absolute go-back-link">
                chevron_left
              </v-icon> 
              <svg width="120px" height="30px" viewBox="0 0 100 30" style="transform: translateX(-6px);">
                <circle cx="10" cy="10" r="9" class="filled"/>
                <line x1="19" y1="10" x2="31" y2="10" class="line"/>
                <circle cx="40" cy="10" r="9" class="filled"/>
                <line x1="61" y1="10" x2="49" y2="10" class="line"/>
                <circle cx="70" cy="10" r="9" class="not-filled"/>
                <line x1="91" y1="10" x2="79" y2="10" class="line"/>
                <circle cx="100" cy="10" r="9" class="not-filled"/>
              </svg>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <p class="text-h8 mb-2 font-weight-bold font-primary">STEP 2</p>
            <div class="text-h1 primary--text">
              Connect to your first source store!
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>
            <v-card class="px-8 pt-10 pb-7 card-rounded not-a-link">
              <v-row>
                <v-col>
                  <div class="text-subtitle-2">
                    Send an invitation to a source store via e-mail.
                  </div>
                  <div class="text-body-2 mt-6">
                    The source store will receive your unique key, instructions to install Syncio, and connect to your store.
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-6">
                <v-col cols="12" class="pb-0">
                  <div class="text-caption caption-color">
                    Enter an e-mail address
                  </div>
                </v-col>
                <v-col cols="9" class="mt-1 pt-1">
                  <v-text-field
                    filled
                    v-model="inviteEmail"
                    @change="$v.inviteEmail.$touch()"
                    :error-messages="checkInviteEmailErrors"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-1">
                  <v-btn class="primary rounded-lg" width="100%" height="48px" @click="sendEmailInvite">
                    <div class="text-body-1">
                      Send Invite
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="text-center mt-7" v-if="isInvited === false">
          <v-col>
            <div class="text-body-2">
              <router-link class="text-decoration-none" to="/shopify/select-plan">Skip for now.</router-link>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-7" v-else>
          <v-col class="text-center">
            <v-btn class="primary rounded-lg" width="150px" height="48px" @click="$router.push('/shopify/select-plan')">
              <div class="text-body-1">
                Next
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingSyncioLogo from "@/views/registration/components/LoadingSyncioLogo";
import {email, required} from "vuelidate/lib/validators";

export default {
  name: "InviteStore",
  components: {LoadingSyncioLogo},
  data (){
    return{
      inviteEmail: "",
      isInvited: false,
    }
  },
  validations: {
    inviteEmail: { required, email },
  },
  computed: {
    ...mapGetters("shop", ["currentShop", "isLoading"]),
    checkInviteEmailErrors () {
      const emailErrors = [];
      if (!this.$v.inviteEmail.$dirty) return emailErrors
      !this.$v.inviteEmail.email && emailErrors.push('Must be valid e-mail')
      !this.$v.inviteEmail.required && emailErrors.push('E-mail is required')
      return emailErrors
    },
  },
  created() {
    this.$store.dispatch(`shop/loadCurrentShop`)
  },
  methods: {
    sendEmailInvite(){
      this.$v.$touch();
      if (!this.$v.$invalid){
        this.$store.dispatch(`shop/sendEmailInvite`, {toEmail: this.inviteEmail, shopId: this.currentShop.id}).then(() => {
          this.isInvited = true;
        })
      }
    }
  }
}
</script>

<style scoped>
.container-max-width {
  max-width: 700px !important;
}
.caption-color{
  color: #525252;
}
#syncio-loading-logo {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>